import React, { useState, useEffect, useRef } from 'react'; 
import Navbar from "../Navbar/Navbar"
import "./home.css"
import AOS from 'aos';
import 'aos/dist/aos.css';
import twitter from "./../../images/twitter.png"
import tiktok from "./../../images/tiktok.png"
import insta from "./../../images/insta.png"
import snap from "./../../images/snap.png"
import OF from "./../../images/OF.png"

import pupi1 from "./../../images/pupi1.png"
import pupi2 from "./../../images/pupi2.png"
import pupi3 from "./../../images/pupi3.png"
import pupi4 from "./../../images/pupi4.png"
import pupi6 from "./../../images/pupi6.png"
import pupi7 from "./../../images/pupi7.png"
import pupi8 from "./../../images/pupi8.png"


AOS.init();


function Home() {

  function presaleRedirect(){
    window.open(
      'https://www.pump.fun/ARm8bPFdDPiZm2iBPw9B7k7kj8mvStq3M6KHojtUYE92',
      '_blank'
    );
  }

  function telegramRedirect(){
    window.open(
      'https://birdeye.so',
      '_blank'
    );
  }

  function twitterRedirect(){
    window.open(
      ' https://twitter.com/PupicoinonSOL',
      '_blank'
    );
  }

  function tiktokRedirect(){
    window.open(
      'https://www.tiktok.com/@raerockhold?_t=8mJvLSsKpl0&_r=1',
      '_blank'
    );
  }

  function ReaTwitterRedirect(){
    window.open(
      'https://x.com/raerockhold?s=21',
      '_blank'
    );
  }

  function snapchatRedirect(){
    window.open(
      'https://snapchat.com/t/30eSTHw3',
      '_blank'
    );
  }

  function instaRedirect(){
    window.open(
      'https://www.instagram.com/raerockhold?igsh=MWQ0NDZqODJmeXgzOA%3D%3D&utm_source=qr',
      '_blank'
    );
  }

  function ofRedirect(){
    window.open(
      'https://onlyfans.com/raerockhold',
      '_blank'
    );
  }

  return (
    <div>
      <div className="home-body">
          <Navbar />
          <div className='home-page-body'>
              <div className='home-inner-body'>
                <div className='home-image'>
                  <img src={pupi1}></img>
                </div>
              </div>
              <div className='hero-title-body'>
                  <div className='hero-title'>
                    <div className='hero-title-han'>PUPI COIN</div>
                    <div className='hero-title-desription'>
                      <p>Look at that cute delivery driver<br></br></p>
                      <p>Here to deliver you gains this bull market<br></br></p>
                    </div>
                    <div className='hero-title-button-body'>
                      <button className='hero-title-button' onClick={presaleRedirect}>BUY $PUPI</button>
                    </div>
                  </div>
              </div>
          </div>
      </div>
      <div className='home-body-bottom'>
        <div className='part-page-body'>
          <div className='home-inner-body'>
            <div className='home-image'>
              <img src={pupi2}></img>
            </div>
          </div>
          <div className='explantion-body'>
              <div className='explantion-text'>
                <h1>ABOUT</h1>
                <p>
                Hi my Name is Rae and this is my $PUPI Aelin.
                <br></br><br></br>
                We bring to you a rug free opportunity to join our amazing community where we guarantee good memes and good vibes.
                <br></br><br></br>
                I've linked all my socials below for full transparency. 
                <br></br><br></br>
                Hope to see you all in TG.❤️
                </p>
                <div className='second-buy-button-container'>
                  <button className='hero-title-button2' onClick={presaleRedirect}>BUY $PUPI</button>
                </div>
              </div>
          </div>
          <div className='home-inner-body'>
            <div className='home-image'>
              <img src={pupi3}></img>
            </div>
          </div>
        </div>
        <div className='part-page-body'>
          <div className='home-inner-body'>
            <div className='home-image'>
              <img src={pupi4}></img>
            </div>
          </div>
          <div className='explantion-body'>
              <div className='explantion-text'>
                <h1>$PUPI TOKENOMICS</h1>
                <p>
                Circulating Supply: <a>1,000,000,000</a> 
                <br></br><br></br>
                Marketing: I have over <a>4</a>M followers and upon maintaining a stable floor, I will use my reach to get us CEX listings.
                <br></br><br></br>
                <a>0</a>% Team Tokens
                <br></br><br></br>
                <a>100</a>% LP Burned
                </p>
              </div>
          </div>
          <div className='home-inner-body'>
            <div className='home-image'>
              <img src={pupi8}></img>
            </div>
          </div>
        </div>
        <div className='part-page-body'>
          <div className='home-inner-body'>
            <div className='home-image'>
              <img src={pupi6}></img>
            </div>
          </div>
            <div className='explantion-body'>
                <div className='explantion-text-bottom'>
                  <h1>SOCIALS</h1>
                  <div className='social-row'>
                    <div className='social-container' onClick={ReaTwitterRedirect}><img className='social-img' src={twitter}></img></div>
                    <div className='social-container' onClick={instaRedirect}><img className='social-img' src={insta}></img></div>
                    <div className='social-container' onClick={tiktokRedirect}><img className='social-img' src={tiktok}></img></div>
                    <div className='social-container' onClick={snapchatRedirect}><img className='social-img' src={snap}></img></div>
                    <div className='social-container' onClick={ofRedirect}><img className='social-img' src={OF}></img></div>
                  </div>
                </div>
            </div>
            <div className='home-inner-body'>
              <div className='home-image'>
                <img src={pupi7}></img>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Home;