import { BrowserRouter as Router, Switch, Route, useLocation } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import Home from "../../pages/home/home";

//import { AnimatePresence } from 'framer-motion/dist/framer-motion';

function AnimatedRoutes() {
    const location = useLocation();

    return(
        //<AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                <Route path="/" exact component={Home} />
                {/* <Route path="/presale" exact component={Presale}/> */}
            </Switch>
        //</AnimatePresence>
    );
}

export default AnimatedRoutes